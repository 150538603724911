<template>
     <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0">
         <v-card  max-width="500">
             <v-card-text>
                 <v-app-bar color="green" dense dark>
                     <v-toolbar-title>{{level}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                     <v-btn icon v-if="id!=null" small>
                        <v-icon >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon v-if="id!=null" @click="deleteNode(id)" small>
                        <v-icon >mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon v-if="id!=null && (level == 'Department'  || level == 'Sub-Department')  " small @click="addJob(id)">
                        <v-icon >mdi-plus</v-icon>
                    </v-btn>

                </v-app-bar>
             </v-card-text>
             <v-card-actions>
                <h4 class="text-h3 font-weight-light mb-3 black--text">
                    {{levelValue}}
                </h4>
             </v-card-actions>
         </v-card>

        <!-- <v-card class="px-5 py-10"  icon="clipboard-text" :title=level>

        </v-card> -->
    </v-container>
</template>

<script>
export default {
     props: ['value','id','deleteNode'],
     computed:{
         level:function(){
             console.log('level')
             console.log(this.value.split(":")[0])
             return this.value.split(":")[0]
         },
         levelValue:function(){
              return this.value.split(":")[1]
         }
     },
     data(){
          return {
              actions:[
                    "Edit",
                    "Delete",
                    "Add Job",
                ],
          }
     },
     methods:{

     }
}
</script>

<style>
.mdi-pencil, .mdi-delete,.mdi-plus{
    color: #000000 !important;
}
</style>