<template>
    <div>
        <select-customer v-on:customer-selected="customerSelected" v-if="!isCustomerUser"></select-customer>
        <!-- <v-form ref="form" v-model="valid" lazy-validation v-if="!isCustomerUser">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="3" md="3">
                        <v-menu :close-on-content-click="false" :nudge-width="200" ref="searchMenu" offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="green" dark v-bind="attrs" class="mt-2 ml-2" v-on="on">
                                    <v-icon class="mr-2" large>mdi-account</v-icon>
                                    {{ selected_Customer.text }}
                                </v-btn>
                            </template>
                            <v-card class="mx-auto" max-width="500">
                                <v-toolbar flat color="transparent">
                                    <v-toolbar-title>Select Customer</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="$refs.search.focus()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-container class="py-0">
                                    <v-row align="center" justify="start">
                                        <v-col v-for="(selection, i) in selections" :key="selection.text" class="shrink">
                                            <v-chip :disabled="loading" close @click:close="selected.splice(i, 1)">
                                                <v-icon left v-text="selection.icon"></v-icon>
                                                {{ selection.text }}
                                            </v-chip>
                                        </v-col>
                                        <v-col v-if="!allSelected" cols="12">
                                            <v-text-field ref="search" v-model="searchItem" full-width hide-details
                                                label="Search" single-line></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-list>
                                    <template v-for="item in categories">
                                        <v-list-item :key="item.customerNumber" :disabled="loading"
                                            v-if="!selected.includes(item)" @click="SetselectedCustomer(item)">
                                            <v-list-item-avatar>
                                                <v-icon :disabled="loading" v-text="item.icon"></v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-title v-text="item.text"></v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                        <v-btn class="ma-2" outlined small fab color="green">
                            <v-icon @click="CustomerProfileSearch()">mdi-magnify</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form> -->

        <v-container id="user-profile" fluid tag="section" v-if="isCustomerUser || selectedCustomer">
            <v-row>
                <v-col cols="12" md="9">
                    <v-stepper class="mt-12" non-linear>
                        <v-stepper-header>
                            <v-stepper-step editable step="1" @click.native="policyclicked = false">
                                Profile Basic Info
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <!-- <v-stepper-step editable step="2" @click.native="policyclicked = true">
                            Policies
                            </v-stepper-step>
                            <v-divider></v-divider> -->
                            <v-stepper-step editable step="2" @click.native="policyclicked = false">
                                Organization Chart
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <BasicInfo ref="BasicInfo" />
                            </v-stepper-content>
                            <!-- <v-stepper-content step="2">
                                <Policies  ref="Policies" />
                            </v-stepper-content> -->
                            <v-stepper-content step="2">
                                <OrgChart ref="OrgChart" />
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-col>
                <v-col cols="12" md="3" class="mt-12">
                    <v-card class="v-card-profile" :avatar="getProfilePic">
                        <v-card-text class="text-center">
                            <v-avatar>
                                <v-img :src="getProfilePic" alt="logo"></v-img>
                            </v-avatar>
                            <h4 class="text-h3 font-weight-light mb-3 black--text">
                                {{ this.$store.getters.customer }}
                            </h4>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <!-- <img v-bind:src= "${this.$baseURL}/CustomerProfile/logo" "~\\images\\5\\6bb27479-2011-42b5-875b-0909baadbd53_font.png" alt="dog" /> -->
                                    <!-- <img src='http://localhost:55023/filepath/5/ec8f0e26-0209-4450-abae-2201dd57d838_97Crrqf9Vj.png' /> -->
                                    <v-btn color="green" dark class="mt-2 ml-2" @click="openAddLogoDialog">
                                        Add Company Logo
                                    </v-btn>
                                </v-col>
                            </v-row>


                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- <h2>Profile</h2>
         <form ref="form" @submit.stop.prevent="CustomerProfileSearch">
        <b-container fluid>
             <b-row class="my-1">
                   <b-col sm="3">
                        <b-form-select
                            v-if="this.$store.getters.customer =='No Customer'"
                            id="ddlCustomers"
                            :options="customerList"
                            text-field="customerName"
                            value-field="customerNumber"
                            type="search"
                            v-model="selectedCustomer">
                            <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select Customer --</b-form-select-option>
                                </template>
                        </b-form-select>
                        <div v-if="this.$store.getters.customer !='No Customer'">
                            {{this.$store.getters.customer}}
                        </div>
                 </b-col>
                <b-col sm="2" >
                        <b-button variant="outline-primary" type="submit">Search</b-button>
                </b-col>
                 <b-col sm="4" >
                </b-col>
                <b-col sm="3" >
                    <h5>
                        Profile is {{ProfilePercent}} % completed
                    </h5>
                </b-col>
             </b-row>
        </b-container>
         </form> -->




        <!-- <b-card no-body>
                <b-tabs pills card>
                    <b-tab title="Basic Info" active>
                        <BasicInfo ref="BasicInfo" />
                    </b-tab>
                    <b-tab title="Policies">
                         <Policies  ref="Policies" />
                    </b-tab>
                    <b-tab title="Organization Chart">
                        <OrgChart ref="OrgChart"  />
                    </b-tab>
                </b-tabs>
            </b-card> -->

        <v-dialog v-model="addComppanyLogoDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Company Logo</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="Addform" v-model="valid" lazy-validation id="Addform">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-file-input id="CompanyLogo" accept="image/*"
                                        :rules="[rules.required, rules.imageSize]" show-size v-model="companyLogo"
                                        label="Company Logo">
                                    </v-file-input>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="addComppanyLogoDialog = false"> Cancel</v-btn>
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="validateInput">Upload</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import BasicInfo from './ProfileComponents/BasicInfo'
//import Policies from './ProfileComponents/Policies'
import OrgChart from './ProfileComponents/OrgChart'
import SelectCustomer from './SelectCustomer';
// import axios from 'axios';
export default {
    components: {
        BasicInfo,
        // Policies,
        OrgChart,
        SelectCustomer
    },
    computed: {
        getProfilePic() {
            let url = this.$baseURL.replace("/API", "");
            let pic = url
                + '/filepath/'
                + this.selected_Customer.customerNumber
                + '/'
                + this.customerProfilePic;
            if (this.selected_Customer.customerNumber < 0) {
                pic = url
                    + '/filepath/'
                    + this.$store.state.userDetails.subscriptionId
                    + '/'
                    + this.customerProfilePic;
            }
            console.log('pic');
            console.log(pic);
            return pic;
        },
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else if (this.selectedCustomer > 0) {
                return this.selectedCustomer
            }
            else
                return -1;

        },
        isCustomerUser() {
            return this.$store.getters.customer != 'No Customer';
        },
        selections() {
            const selections = []

            for (const selection of this.selected) {
                selections.push(selection)
            }

            return selections
        },
        allSelected() {
            return this.selected.length === this.newCustomerList.length
        },
        categories() {
            const search = this.searchItem.toLowerCase()

            if (!search) return this.newCustomerList

            return this.newCustomerList.filter(item => {
                const text = item.text.toLowerCase()

                return text.indexOf(search) > -1
            })
        },
    },
    watch: {
        selected() {
            this.searchItem = ''
        },
    },
    data() {
        return {
            policyclicked: false,
            selectedItem: null,
            searchItem: '',
            customerProfilePic: '',
            customerList: [],
            newCustomerList: [],
            selectedCustomer: null,
            ProfilePercent: 0,
            companyLogo: null,
            selected: [],
            loading: false,
            addComppanyLogoDialog: false,
            selected_Customer: {
                text: 'Select Customer',
                customerNumber: -1
            },
            valid: false,
            rules: {
                required: value => !!value || 'Required Field',
                imageSize: value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
            },
        }
    },

    methods: {
        customerSelected(cn) {
            this.selected_Customer = this.newCustomerList.filter(x => x.customerNumber == cn.customerNumber)[0];
            this.selectedCustomer = this.selected_Customer.customerNumber;
            this.CustomerProfileSearch();
        },
        openAddLogoDialog() {
            this.companyLogo = null;
            this.addComppanyLogoDialog = true
        },
        validateInput() {
            if (this.$refs.Addform.validate()) {
                this.UploadCompanyLogo();
            }
        },
        SetselectedCustomer(item) {
            this.selected_Customer = item;
            this.selectedCustomer = this.selected_Customer.customerNumber
            //this.loadCustomerData(item.customerNumber)
        },
        UploadCompanyLogo() {
            // var form = document.getElementById('Addform');
            const formData = new FormData();
            formData.append("companyLogoFromClientSide", this.companyLogo)

            this.$axios({
                method: 'post',
                url: `${this.$baseURL}/CustomerProfile/logo`,
                headers: { 'Content-Type': 'multipart/form-data' },
                //  headers: {'Content-Type': 'application/json' },
                data: formData,
                params: {
                    CustomerNumber: this.customerNumber
                }
            }).then((response) => {
                this.addComppanyLogoDialog = false;
                this.customerProfilePic = response.data
            });


        },
        retrieveProfilePic(customerNumber) {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/logo`,
                headers: { 'Content-Type': 'application/json' },
                //  headers: {'Content-Type': 'application/json' },
                params: {
                    CustomerNumber: customerNumber
                }
            }).then((response) => {
                this.addComppanyLogoDialog = false;
                this.customerProfilePic = response.data
            });
        },
        CustomerProfileSearch() {
            this.retrieveProfilePic(this.selected_Customer.customerNumber);
            setTimeout(() => {
                this.$refs.BasicInfo.getProfileDetails(this.selected_Customer.customerNumber);
                //this.$refs.Policies.getPolicy(this.selected_Customer.customerNumber);
                this.$refs.OrgChart.getOrgCharts(this.selected_Customer);
            }, 1000);

        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
    mounted() {
        if (this.customerNumber == -1) { //then load cutomers
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/customersList`,
            })
                .then((response) => {
                    this.customerList = response.data.customersList;
                    this.newCustomerList = response.data.customersList
                        .map(customer => ({
                            text: customer.customerName,
                            icon: 'mdi-map-marker',
                            customerNumber: customer.customerNumber
                        }))
                });

            // this.getPositions(1)
        } else {
            this.retrieveProfilePic(this.$store.state.userDetails.subscriptionId);
        }
    }
}
</script>
<style>
.display-inline label {
    display: inline !important;
}
</style>