<template>
    <div>
        <v-progress-linear :active="loading" color="green" :indeterminate="loading" class="ma-0" height="4"
            style="top: -2px;">Loading...</v-progress-linear>
        <v-card class="px-5 py-10">

            <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-tooltip top v-model="legalNamett">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-model="form.legalName" :rules="[rules.required]" required
                                        label="legal Name" type="text" append-outer-icon="mdi-information"
                                        @click:append-outer="legalNamett = !legalNamett">
                                        >
                                    </v-text-field>
                                </template>
                                <span>Company's name as per the Commercial Register</span>
                            </v-tooltip>

                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-tooltip top v-model="commercialNamett">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-model="form.commercialName" :rules="[rules.required]" required
                                        label="Commercial Name" type="text" append-outer-icon="mdi-information"
                                        @click:append-outer="commercialNamett = !commercialNamett">
                                    </v-text-field>
                                </template>
                                <span>Company's commercial name in the market</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- <v-col cols="12" sm="6" md="6">
                            <v-tooltip top v-model="legalStructurett">
                                <template v-slot:activator="{ }">
                                    <v-select v-bind="attr" v-on="on" v-model="form.legalStructure"
                                        :items="legalStructureOptions" :rules="[rules.required]" label="Legal Structure"
                                        required append-outer-icon="mdi-information"
                                        @click:append-outer="legalStructurett = !legalStructurett">
                                    </v-select>
                                </template>
                                <span>The Company legal structure and shareholding structure i.e. Sole proprietorship,
                                    partnership, etc.…</span>
                            </v-tooltip>
                        </v-col> -->
                        <v-col cols="12" sm="6" md="6">
                            <v-tooltip right v-model="YearOfEstablishmenttt">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-bind="attr" v-on="on" v-model="form.yearOfEstablishment"
                                        :rules="[rules.required, rules.yearRule]" required label="Year Of Establishment"
                                        type="number" append-outer-icon="mdi-information"
                                        @click:append-outer="YearOfEstablishmenttt = !YearOfEstablishmenttt">
                                    </v-text-field>
                                </template>
                                <span>Year of establishment</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-tooltip top v-model="Websitett">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-model="form.webSite" required :rules="[rules.websiteRule]"
                                        label="website" type="text" append-outer-icon="mdi-information"
                                        @click:append-outer="Websitett = !Websitett">
                                    </v-text-field>
                                </template>
                                <span>Website URL</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                                 <v-col cols="12" sm="12" md="12">
                                     <v-file-input
                                        accept="image/*"
                                        :rules="[rules.imageSize]"
                                        show-size
                                        v-model="form.companyLogo"
                                        label="Company Logo">
                                    </v-file-input>
                                 </v-col>
                             </v-row> -->
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-tooltip top v-model="BusinessDesctt">
                                <template v-slot:activator="{ }">
                                    <v-textarea outlined rows="3" label="Business Description" :rules="[rules.required]"
                                        required v-model="form.businessDescription" append-outer-icon="mdi-information"
                                        @click:append-outer="BusinessDesctt = !BusinessDesctt">
                                    </v-textarea>
                                </template>
                                <span>A brief description about the company's background, activity and focus</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="IndustryTypett">
                                <template v-slot:activator="{ }">
                                    <v-select v-model="form.industryType" :items="industryTypeOptions"
                                        :rules="[rules.required]" label="Industry Type" item-text="englishName"
                                        item-value="id" @change="industryTypeChanged" required
                                        append-outer-icon="mdi-information"
                                        @click:append-outer="IndustryTypett = !IndustryTypett">
                                    </v-select>
                                </template>
                                <span>Identifying their industry or industries (if a holding/diversified company). This
                                    should be based on a global acknowledged categorization as TRBC/GICS i.e. Consumer
                                    Staples</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="BusinessSectortt">
                                <template v-slot:activator="{ }">
                                    <v-select v-model="form.businessSector" :items="businessSectorsOptions"
                                        :rules="[rules.required]" label="Business Sector" no-data-text="no items"
                                        item-text="englishName" item-value="id" :disabled="form.industryType == null"
                                        @change="businessSectorChanged" required append-outer-icon="mdi-information"
                                        @click:append-outer="BusinessSectortt = !BusinessSectortt">
                                    </v-select>
                                </template>
                                <span>Identifying their business sectors (if a holding/diversified company). This should be
                                    based on a global acknowledged categorization as TRBC/GICS i.e. Food & Beverage</span>
                            </v-tooltip>
                        </v-col>

                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="BusinessSubSectortt">
                                <template v-slot:activator="{ }">
                                    <v-select v-model="form.businessSubSector" :items="businessSubSectorsOptions"
                                        :rules="[rules.required]" label="Sub-Sector" item-text="englishName" item-value="id"
                                        :disabled="form.businessSector == null" required append-outer-icon="mdi-information"
                                        @click:append-outer="BusinessSubSectortt = !BusinessSubSectortt">
                                    </v-select>
                                </template>
                                <span>Identifying their business sub-sectors (if a holding/diversified company). This should
                                    be based on a global acknowledged categorization as TRBC/GICS i.e. Canned Food</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="CountryOfInctt">
                                <template v-slot:activator="{ }">
                                    <v-select v-model="form.countryOfIncorp" :items="countriesOptions"
                                        :rules="[rules.required]" label="Country Of Incorporation" item-text="countryName"
                                        item-value="id" @change="countryOfIncorpChanged" required
                                        append-outer-icon="mdi-information"
                                        @click:append-outer="CountryOfInctt = !CountryOfInctt">
                                    </v-select>
                                </template>
                                <span>Company's Head-Quarters location by city and country</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-select v-model="form.cityOfIncorp" :items="citiesOfIncorpOptions" :rules="[rules.required]"
                                label="City of Incorporation" item-text="cityName" item-value="id"
                                :disabled="form.countryOfIncorp == null" required>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="CountryOfOptt">
                                <template v-slot:activator="{ }">
                                    <v-select v-model="form.countryOfOperation" :items="countriesOptions"
                                        :rules="[rules.required]" label="Country Of Operation" item-text="countryName"
                                        item-value="id" @change="countryOfOperationChanged" required
                                        append-outer-icon="mdi-information"
                                        @click:append-outer="CountryOfOptt = !CountryOfOptt">
                                    </v-select>
                                </template>
                                <span>Company's operations' location by city and country</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-select v-model="form.cityOfOperation" :items="citiesOfOpOptions" :rules="[rules.required]"
                                label="City of Operation" item-text="cityName" item-value="id"
                                :disabled="form.countryOfIncorp == null" required>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-tooltip top v-model="servicestt">
                                <template v-slot:activator="{ }">
                                    <v-text-field min=0 v-model="form.numOfSvcOrProd" :rules="[rules.required]" required
                                        label="Number of products" type="number" append-outer-icon="mdi-information"
                                        @click:append-outer="servicestt = !servicestt">
                                    </v-text-field>
                                </template>
                                <span>Number of Products/services by the company</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-tooltip top v-model="revenuett">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-model="form.revenue" :rules="[rules.required]" required min=0
                                        label="Revenue" type="number" append-outer-icon="mdi-information"
                                        @click:append-outer="revenuett = !revenuett">
                                    </v-text-field>
                                </template>
                                <span>Company's annual revenue in EGP (Average of past 3 years, if less than 3 years from
                                    establishment, insert actual to date and average 3 years forecasted revenue)</span>
                            </v-tooltip>

                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-tooltip top v-model="totalPayroltt">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-model="form.totalPayrollCost" :rules="[rules.required]" required
                                        label="Total Payroll Cost" min=0 type="number" append-outer-icon="mdi-information"
                                        @click:append-outer="totalPayroltt = !totalPayroltt">
                                    </v-text-field>
                                </template>
                                <span>Basic salaries+ allowances+ short & Long term incentives+ social insurance ER+ Medical
                                    & Life cost X 12 months for the whole headcount contracted by the company excluding
                                    outsourced and managed service staff
                                    (Average of past 3 years, if less than 3 years from establishment, insert actual to
                                    dates and average 3 years forecasted Payroll cost)</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-tooltip top v-model="annualMerittt">
                                <template v-slot:activator="{ }">
                                    <v-text-field prefix="%" v-model="form.annualMerit" :rules="[rules.required]" required
                                        label="Annual Merit" min=0 type="number" append-outer-icon="mdi-information"
                                        append-icon="mdi-percent-outline"
                                        @click:append-outer="annualMerittt = !annualMerittt">
                                    </v-text-field>
                                </template>
                                <span>Please insert the on average / range % of merit + Cost of Living + performance
                                    increase trend in the company </span>
                            </v-tooltip>

                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-tooltip top v-model="totalHeadcounttt">
                                <template v-slot:activator="{ }">
                                    <v-text-field v-model="form.headcountNumber" :rules="[rules.required]" required
                                        label="Headcount number to-date" min=0 type="number"
                                        append-outer-icon="mdi-information"
                                        @click:append-outer="totalHeadcounttt = !totalHeadcounttt">
                                    </v-text-field>
                                </template>
                                <span>Total Headcount as per latest payroll closing contracted by the company excluding
                                    outsourced and managed service staff</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="ownerNamett">
                                <template v-slot:activator="{ }">
                                    <v-text-field id="txtContactPerson" v-model="form.ownerName" label="Owner Name"
                                        :rules="[rules.required]" required type="text" append-outer-icon="mdi-information"
                                        @click:append-outer="ownerNamett = !ownerNamett" />

                                </template>
                                <span>Company's CEO/ General Manager or similar leading position for the entity</span>
                            </v-tooltip>
                        </v-col>

                        <v-col cols="4" md="4">
                            <v-text-field id="txtContactPersonMail" v-model="form.ownerEMail" label="Owner Mail"
                                :rules="[rules.required, rules.emailRule]" required type="text" />
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-text-field id="txtContactPersonMobile" v-model="form.ownerTelNumber" required
                                label="Owner Mobile" class="green-input" :rules="[rules.required]" type="text" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" md="4">
                            <v-tooltip top v-model="contactPersontt">
                                <template v-slot:activator="{ }">
                                    <v-text-field id="txtContactPerson" class="green-input" v-model="form.contactPerson"
                                        label="Contact Person" :rules="[rules.required]" type="text"
                                        append-outer-icon="mdi-information"
                                        @click:append-outer="contactPersontt = !contactPersontt" />

                                </template>
                                <span>Company official logo for report and marketing purposes</span>
                            </v-tooltip>

                        </v-col>

                        <v-col cols="4" md="4">
                            <v-text-field id="txtContactPersonMail" class="green-input" v-model="form.contactPersonMail"
                                label="Contact Person Mail" :rules="[rules.required, rules.emailRule]" type="text" />
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-text-field id="txtContactPersonMobile" class="green-input" v-model="form.contactPersonMobile"
                                label="Contact Person Mobile" :rules="[rules.required]" type="text" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn color="success" class="mr-0" :disabled="!valid" @click="SaveProfile">
                                Submit Reqeust
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-progress-linear :active="loading" color="green" :indeterminate="loading" absolute bottom
                height="15">Loading...</v-progress-linear>`
        </v-card>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
export default {
    mixins: [validationMixin],
    computed: {
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else {
                return this.currentSelectedCustomer
            }
        },
    },
    data() {
        return {
            rules: {
                required: value => !!value || 'Required Field',
                yearRule: value => value >= 1950 && value <= 2021 || 'Year should be between 1950 and 2021',
                emailRule: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-mail must be valid',
                websiteRule: v => /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(v) || 'Website must be valid',
                imageSize: value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
            },
            loading: false,
            legalNamett: false,
            commercialNamett: false,
            //legalStructurett: false,
            YearOfEstablishmenttt: false,
            Websitett: false,
            BusinessDesctt: false,
            IndustryTypett: false,
            BusinessSectortt: false,
            BusinessSubSectortt: false,
            CountryOfInctt: false,
            CountryOfOptt: false,
            servicestt: false,
            revenuett: false,
            totalPayroltt: false,
            annualMerittt: false,
            totalHeadcounttt: false,
            ownerNamett: false,
            contactPersontt: false,
            valid: true,
            currentSelectedCustomer: null,
            form: {
                id: 0,
                legalName: null,
                commercialName: null,
                //legalStructure: null,
                yearOfEstablishment: null,
                industryType: null,
                businessSector: null,
                businessSubSector: null,
                revenue: null,
                totalPayrollCost: null,
                annualMerit: null,
                headcountNumber: null,
                companyOwnerName: null,
                ownerMail: null,
                ownerMobile: null,
                contactPerson: null,
                contactPersonMail: null,
                contactPersonMobile: null,
                webSite: null,
                businessDescription: null,
                countryOfIncorp: null,
                cityOfIncorp: null,
                countryOfOperation: null,
                cityOfOperation: null,
                numOfSvcOrProd: null,
                companyLogo: null,
            },
            //legalStructureOptions: [],
            industryTypeOptions: [],
            businessSectorsAllOptions: [],
            businessSectorsOptions: [],
            businessSubSectorsAllOptions: [],
            businessSubSectorsOptions: [],
            countriesOptions: [],
            citiesOfIncorpOptions: [],
            citiesOfOpOptions: [],
            citiesAllOptions: [],
            revenueMessage: "Company's annual revenue in EGP (Average of past 3 years, if less than 3 years from establishment, insert actual to date and average 3 years forecasted revenue)",
            AnnualMeritMessage: "Please insert the on average / range % of merit + Cost of Living + performance increase trend  in the company ",
            TotalPayrollCostMessage: 'Basic salaries+ allowances+ short & Long term incentives+ social insurance ER+ Medical & Life cost X 12 months for the whole headcount contracted by the company excluding outsourced and managed service staff' +
                '(Average of past 3 years, if less than 3 years from establishent, insert actual to dates and average 3 years forecasted Payroll cost)',

        }
    },
    methods: {
        countryOfIncorpChanged(item) {
            //  if(this.$store.getters.getCities[0]==null)
            //     this.$store.state.splice(0,1);
            // if( this.$store.getters.getCities!=null &&  this.$store.getters.getCities.length > 0){
            //     this.citiesOfIncorpOptions =  this.$store.getters.getCities.filter(c=>c.countryId == item);
            // }
            if (this.citiesOfIncorpOptions == null || this.citiesOfIncorpOptions.length == 0) {
                this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/CustomerProfile/cities?countryId=${item}`,
                }).then((response) => {
                    this.$store.commit('updateCities', response.data);
                    this.citiesOfIncorpOptions = response.data;
                });
            }
            // console.log(item)
        },
        countryOfOperationChanged(item) {
            // if(this.$store.getters.getCities[0]==null)
            //     this.$store.state.splice(0,1);
            // if(this.$store.state.cities !=null && this.$store.state.cities.length > 0 ){
            //     this.citiesOfOpOptions = this.$store.state.cities.filter(c=>c.countryId == item);
            // }
            if (this.citiesOfOpOptions == null || this.citiesOfOpOptions.length == 0) {
                this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/CustomerProfile/cities?countryId=${item}`,
                }).then((response) => {
                    this.$store.commit('updateCities', response.data);
                    this.citiesOfOpOptions = response.data;
                });
            }

        },
        industryTypeChanged(item) {
            console.log('this.businessSectorsAllOptions')
            console.log(this.businessSectorsAllOptions)
            this.businessSectorsOptions = this.businessSectorsAllOptions.filter(b => b.industryTypeId == item);
        },
        businessSectorChanged(item) {
            this.businessSubSectorsOptions = this.businessSubSectorsAllOptions.filter(b => b.businessSectorId == item);
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        SaveProfile() {
            this.loading = true;
            let formData = new FormData();
            formData.append('file', this.file)
            let requestData = {
                Id: this.form.id,
                CustomerNumber: this.customerNumber,
                LegalName: this.form.legalName,
                CommercialName: this.form.commercialName,
                //LegalStructureId: this.form.legalStructure,
                YearOfEstablishment: Number(this.form.yearOfEstablishment),
                Website: this.form.webSite,
                BusinessDescription: this.form.businessDescription,
                IndustryTypeId: this.form.industryType,
                BusinessSectorId: this.form.businessSector,
                BusinessSubSectorId: this.form.businessSubSector,
                CountryOfIncorporationId: this.form.countryOfIncorp,
                CityOfIncorporationId: this.form.cityOfIncorp,
                CountryOfOperationId: this.form.countryOfOperation,
                CityOfOperationId: this.form.cityOfOperation,
                NumberOfProductsOrServices: Number(this.form.numOfSvcOrProd),
                Revenue: Number(this.form.revenue),
                TotalPayrollCost: Number(this.form.totalPayrollCost),
                AnnualMeritCyclePolicy: Number(this.form.annualMerit),
                HeadcountNumber: Number(this.form.headcountNumber),
                OwnerName: this.form.ownerName,
                OwnerEMail: this.form.ownerEMail,
                OwnerTelNumber: this.form.ownerTelNumber,
                ContactPersonName: this.form.contactPerson,
                ContactPersonEMail: this.form.contactPersonMail,
                ContactPersonTelNumber: this.form.contactPersonMobile,
                // companyLogoFromClientSide:this.form.companyLogo
            }
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile`,
                headers: { 'Content-Type': 'application/json' },
                data: requestData
            })
                // .catch((error)=>{
                //     this.$bvModal.msgBoxOk(error, {
                //                             title: 'Confirmation',
                //                             size: 'sm',
                //                             buttonSize: 'sm',
                //                             okVariant: 'success',
                //                             headerClass: 'p-2 border-bottom-0',
                //                             footerClass: 'p-2 border-top-0',
                //                             centered: true
                //                             });
                // })
                .then((response) => {

                    this.$bvModal.msgBoxOk("Succeeded", {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.form.id = response.data;
                    this.loading = false;
                });

        },
        getProfileLookups() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/lookups`,
            })
                .then((response) => {

                    // this.$store.commit('updateLegalStructures', response.data.legalStructures.map(v => ({
                    //     value: v.id,
                    //     text: v.englishName
                    // })));
                    // this.legalStructureOptions = response.data.legalStructures.map(v => ({
                    //     value: v.id,
                    //     text: v.englishName
                    // }))
                    this.industryTypeOptions = response.data.industryTypes;
                    this.businessSectorsAllOptions = response.data.businessSectors;
                    this.businessSubSectorsAllOptions = response.data.businessSubSectors;
                    this.countriesOptions = response.data.countries;
                    this.$store.commit('updateCities', response.data.cities);
                });

            // if(this.$store.getters.getIndustryTypes.length == 0){
            //         this.$axios({
            //         method: 'get',
            //         url: `${this.$baseURL}/CustomerProfile/lookups`,
            //     })
            //     .then((response)=>{

            //         this.$store.commit('updateLegalStructures', response.data.legalStructures.map(v=>({
            //             value:v.id,
            //             text:v.englishName
            //         })));
            //         this.$store.commit('updateIndustryTypes',response.data.industryTypes);
            //         this.$store.commit('updateBusinessSectors',response.data.businessSectors);
            //         this.$store.commit('updateBusinessSubSectors',response.data.businessSubSectors);
            //         this.$store.commit('updateCountries',response.data.countries);
            //         this.$store.commit('updateCities',response.data.cities);
            //     });
            // }
            // this.legalStructureOptions = this.$store.getters.getLegalStructures;
            // this.industryTypeOptions = this.$store.getters.getIndustryTypes;
            // this.businessSectorsAllOptions = this.$store.getters.getBusinessSectors;
            // this.businessSubSectorsAllOptions = this.$store.getters.getBusinessSubSectors;
            // this.countriesOptions = this.$store.getters.getCountries;
            // this.citiesAllOptions = this.$store.getters.getCities;
        },
        getProfileDetails(selctedCustNumber) {
            if (selctedCustNumber == null)
                selctedCustNumber = this.customerNumber;
            else
                this.currentSelectedCustomer = selctedCustNumber;
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile?CustomerNumber=${selctedCustNumber}`,
                headers: { 'Content-Type': 'application/json' },
            }).then((response) => {
                console.log('response')
                console.log(response)
                this.form.id = response.data.id;
                this.form.legalName = response.data.legalName;
                this.form.commercialName = response.data.commercialName;
                //this.form.legalStructure = response.data.legalStructureId;
                this.form.yearOfEstablishment = response.data.yearOfEstablishment;
                this.form.webSite = response.data.website;
                this.form.businessDescription = response.data.businessDescription;
                this.form.industryType = response.data.industryTypeId;
                this.form.businessSector = response.data.businessSectorId;
                this.form.businessSubSector = response.data.businessSubSectorId;
                this.form.countryOfIncorp = response.data.countryOfIncorporationId;
                this.form.cityOfIncorp = response.data.cityOfIncorporationId;
                this.form.countryOfOperation = response.data.countryOfOperationId;
                this.form.cityOfOperation = response.data.cityOfOperationId;
                this.form.numOfSvcOrProd = response.data.numberOfProductsOrServices;
                this.form.revenue = response.data.revenue;
                this.form.totalPayrollCost = response.data.totalPayrollCost;
                this.form.annualMerit = response.data.annualMeritCyclePolicy;
                this.form.headcountNumber = response.data.headcountNumber;
                this.form.ownerName = response.data.ownerName;
                this.form.ownerEMail = response.data.ownerEMail
                this.form.ownerTelNumber = response.data.ownerTelNumber;
                this.form.contactPerson = response.data.contactPersonName;
                this.form.contactPersonMail = response.data.contactPersonEMail;
                this.form.contactPersonMobile = response.data.contactPersonTelNumber;




                this.businessSectorsOptions = this.businessSectorsAllOptions.filter(b => b.industryTypeId == response.data.industryTypeId);
                this.businessSubSectorsOptions = this.businessSubSectorsAllOptions.filter(b => b.businessSectorId == response.data.businessSectorId);
                this.countryOfIncorpChanged(response.data.countryOfIncorporationId)
                this.countryOfOperationChanged(response.data.countryOfOperationId)
            });
        }
    },
    mounted() {
        this.getProfileLookups();
        if (this.customerNumber > 0) {
            this.$nextTick(() => {
                this.getProfileDetails();
            });

        }
    }
}
</script>

<style></style>