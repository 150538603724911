<template>
    <div>
        <v-icon class="mr-2" large @click="() => this.$refs.tree.restoreScale()">mdi-magnify</v-icon>
        <v-icon class="mr-2" large @click="() => this.$refs.tree.zoomIn()">mdi-magnify-plus-outline</v-icon>
        <v-icon class="mr-2" large @click="() => this.$refs.tree.zoomOut()">mdi-magnify-minus-outline</v-icon>
        <v-menu top offset-x="true">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on"> Add </v-btn>
            </template>
            <v-list>
                <v-list-item link v-for="(item, index) in heirarchyActions" :key="index" @click="addDialog(item, index)">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div class="containersss">
            <vue-tree style="width: 800px; height: 600px; border: 1px solid gray;" :dataset="orgChart" :config="treeConfig"
                linkStyle="straight" ref="tree">
                <template v-slot:node="{ node }">
                        <orgChartLevel :value="node.name" :id="node.id" :deleteNode="deleteNode" />
                </template>
            </vue-tree>
        </div>
        <v-dialog v-model="addHeirarchyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add {{ addedLevelName }} </span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="Addform" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row v-if="levelToAdd == 0">
                                <v-col cols="12" sm="6" md="6">
                                    <v-select v-model="editedItem.countryId" :items="filteredCountries"
                                        :rules="[rules.required, rules.validselection]" label="Countries"
                                        item-text="countryName" item-value="id" required>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6" v-if="levelToAdd > 0">
                                    <v-select v-model="editedItem.countryId" :items="orgChartCountries"
                                        :rules="[rules.required, rules.validselection]" label="Countries"
                                        item-text="countryName" item-value="id" @change="countryChanged" required>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="levelToAdd == 1">
                                <v-col cols="12" sm="6" md="6">
                                    <v-select v-model="editedItem.cityId" :items="coumtryCities"
                                        :rules="[rules.required, rules.validselection]" label="City" item-text="cityName"
                                        item-value="id" :disabled="editedItem.countryId == -1" required>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="levelToAdd > 1">
                                <v-col cols="12" sm="6" md="6">
                                    <v-select v-model="editedItem.cityId" :items="coumtryCities"
                                        :rules="[rules.required, rules.validselection]" label="City" item-text="cityName"
                                        item-value="id" @change="cityChanged" :disabled="editedItem.countryId == -1"
                                        required>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="levelToAdd == 2">
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.department" :rules="[rules.required]" required
                                        label="Department">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="levelToAdd == 3">
                                <v-col cols="12" sm="6" md="6">
                                    <v-select v-model="editedItem.department" :items="cityDepartments"
                                        :rules="[rules.required, rules.validselection, rules.departmentExists]"
                                        label="Department" :disabled="editedItem.cityId == -1" required>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="levelToAdd == 3">
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.subDepartment" :rules="[rules.required]" required
                                        label="Sub-Department">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.orgChartLayer" :rules="[rules.required]" required
                                        label="Organization Layer">
                                    </v-text-field>
                                </v-col>
                            </v-row> -->
                            <v-row v-if="addedLevelName == 'Department'">
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.departmentHeadTitle" :rules="[rules.required]"
                                        required label="Title of the department Head">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="addHeirarchyDialog = false"> Cancel</v-btn>
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="validateNew">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="text-center">
            <v-snackbar v-model="snackbar" timeout="3000">
                {{ alertMsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="approveDeleteNodeDialig" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">ِThis will delete this node and all its children, proceed ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteOrgStructure">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="approveDeleteNodeDialig = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- <v-dialog v-model="addJobDialog" max-width="500px">
                    <v-form ref="BasicInfoForm" v-model="validLTIForm" >
                        <v-container>

                        </v-container>
                    </v-form>
                </v-dialog> -->

        <!-- <b-card-group deck>
                <b-card
            header="Heirarchy Data"
            header-tag="header">
            <form ref="form" @submit.stop.prevent="addOrgChart">
                    <b-container fluid>
                        <b-row class="my-1">
                            <b-col sm="2" >
                            <b-form-group
                                label="Country"
                                label-for="ddlOrgChartCountry">
                                <b-form-select
                                    id="ddlOrgChartCountry"
                                    name="ddlOrgChartCountry"
                                    v-model="$v.form.orgChartCountry.$model"
                                    :options="countries"
                                    :state="validateState('orgChartCountry')"
                                    aria-describedby="orgChartCountry-feedback"
                                    text-field="countryName"
                                    value-field="id"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    @change="countryChanged">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- select Country --</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback id="orgChartCountry-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="City"
                                label-for="ddlOrgChartCity">
                                <b-form-select
                                    id="ddlOrgChartCity"
                                    name="ddlOrgChartCity"
                                    v-model="$v.form.orgChartCity.$model"
                                    :options="coumtryCities"
                                    :state="validateState('orgChartCity')"
                                    aria-describedby="orgChartCity-feedback"
                                    text-field="cityName"
                                    value-field="id"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    :disabled="form.orgChartCountry==null">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- select City --</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback id="orgChartCity-feedback">
                                    This is a required field.
                                </b-form-invalid-feedback>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Department"
                                label-for="txtOrgChartDepartment">
                                <b-form-input
                                    id="txtOrgChartDepartment"
                                    placeholder="Enter Department"
                                    v-model="$v.form.orgChartDepartment.$model"
                                    :state="validateState('orgChartDepartment')"
                                    type="text"
                                    aria-describedby="orgChartDepartment-feedback">
                                </b-form-input>
                                <b-form-invalid-feedback id="orgChartCity-feedback">
                                        *
                                </b-form-invalid-feedback>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Sub-Department"
                                label-for="txtOrgChartSubDepartment">
                                <b-form-input
                                    id="txtOrgChartSubDepartment"
                                    placeholder="Sub-Department"
                                    v-model="form.orgChartSubDepartment"
                                    type="text">
                                </b-form-input>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Grade"
                                label-for="txtOrgChartGrade">
                                <b-form-input
                                    id="txtOrgChartGrade"
                                    placeholder="Grade"
                                    v-model="form.orgChartGrade"
                                    type="text">
                                </b-form-input>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Layer"
                                label-for="txtOrgChartLayer">
                                <b-form-input
                                    id="txtOrgChartLayer"
                                    placeholder="Layer"
                                    v-model="form.orgChartLayer"
                                    type="text">
                                </b-form-input>
                            </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="my-1">
                            <b-col sm="10" >
                            </b-col>
                            <b-col sm="2" >
                            <b-button type="submit" variant="primary" :disabled="this.customerNumber == null">Add</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
            </form>
        </b-card>
        </b-card-group>
        <b-table
            striped hover
            :items="orgStructureItems"
            :fields="orgStructureItemsfields"
            ref="orgStructureList">
            <template #cell(Action)="row">
                <b-button size="sm" class="mr-2"  @click="editOrgStructure(row.item,row.index)" >Edit</b-button>
                <b-button size="sm" class="mr-2" variant="danger" @click="deleteOrgStructure(row.item)">Delete</b-button>
            </template>
        </b-table>
        <b-modal id="EditOrgStructure" title="Edit Organization Structure" size="xl" hide-footer hide-backdrop>
            <form ref="form" @submit.stop.prevent="updateOrgStructure">
                 <b-form-group
                label="Id"
                label-for="Id" >
                   {{this.editForm.id}}
                </b-form-group>
                 <b-container fluid>
                        <b-row class="my-1">
                            <b-col sm="2" >
                            <b-form-group
                                label="Country"
                                label-for="ddlOrgChartCountry">
                                <b-form-select
                                    id="ddlOrgChartCountry"
                                    name="ddlOrgChartCountry"
                                    v-model="$v.editForm.orgChartCountry.$model"
                                    :options="countries"
                                    :state="validateState('orgChartCountry')"
                                    aria-describedby="orgChartCountry-feedback"
                                    text-field="countryName"
                                    value-field="id"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    @change="countryChanged">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- select Country --</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback id="orgChartCountry-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="City"
                                label-for="ddlOrgChartCity">
                                <b-form-select
                                    id="ddlOrgChartCity"
                                    name="ddlOrgChartCity"
                                    v-model="$v.editForm.orgChartCity.$model"
                                    :options="coumtryCities"
                                    :state="validateState('orgChartCity')"
                                    aria-describedby="orgChartCity-feedback"
                                    text-field="cityName"
                                    value-field="id"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    :disabled="form.orgChartCountry==null">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- select City --</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback id="orgChartCity-feedback">
                                    This is a required field.
                                </b-form-invalid-feedback>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Department"
                                label-for="txtOrgChartDepartment">
                                <b-form-input
                                    id="txtOrgChartDepartment"
                                    placeholder="Enter Department"
                                    v-model="$v.editForm.orgChartDepartment.$model"
                                    :state="validateState('orgChartDepartment')"
                                    type="text"
                                    aria-describedby="orgChartDepartment-feedback">
                                </b-form-input>
                                <b-form-invalid-feedback id="orgChartCity-feedback">
                                        *
                                </b-form-invalid-feedback>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Sub-Department"
                                label-for="txtOrgChartSubDepartment">
                                <b-form-input
                                    id="txtOrgChartSubDepartment"
                                    placeholder="Sub-Department"
                                    v-model="form.orgChartSubDepartment"
                                    type="text">
                                </b-form-input>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Grade"
                                label-for="txtOrgChartGrade">
                                <b-form-input
                                    id="txtOrgChartGrade"
                                    placeholder="Grade"
                                    v-model="editForm.orgChartGrade"
                                    type="text">
                                </b-form-input>
                            </b-form-group>
                            </b-col>
                            <b-col sm="2" >
                            <b-form-group
                                label="Layer"
                                label-for="txtOrgChartLayer">
                                <b-form-input
                                    id="txtOrgChartLayer"
                                    placeholder="Layer"
                                    v-model="editForm.orgChartLayer"
                                    type="text">
                                </b-form-input>
                            </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="my-1">
                            <b-col sm="10" >
                            </b-col>
                            <b-col sm="2" >
                            <b-button type="submit" variant="primary">Update</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
            </form>
        </b-modal> -->
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueTree from '@ssthouse/vue-tree-chart'
import orgChartLevel from './OrgChartLevel.vue'
import Guid from '../../utils/Guid';
export default {
    mixins: [validationMixin],
    computed: {
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else {
                return this.currentSelectedCustomer
            }
        },
        filteredCountries() {
            let Addedcountries = [];
            Addedcountries = Array.from(new Set(this.orgStructureItems.map((item) => item.country)));
            return this.countries.filter(function (country) {
                return Addedcountries.indexOf(country.countryName) == -1
            })

        },
        orgChartCountries() {
            let Addedcountries = [];
            Addedcountries = Array.from(new Set(this.orgStructureItems.map((item) => item.country)));
            return this.countries.filter(function (country) {
                return Addedcountries.indexOf(country.countryName) != -1
            })
        },
        getCustomerName() {
            if (this.$store.adminSelectedCustomer == null)
                return "Company:" + this.$store.getters.customer
            return "Company:" + this.$store.adminSelectedCustomer.text
        }


    },
    components: {
        'vue-tree': VueTree,
        orgChartLevel
    },
    data() {
        return {
            loading: false,
            AdminSelectedCustomerName: null,
            selectedDeleteNode: null,
            selectedNode: null,
            approveDeleteNodeDialig: false,
            addJobDialog: false,
            snackbar: false,
            alertMsg: '',
            cityDepartments: [],
            editedItem: {
                id: -1,
                countryId: -1,
                cityId: -1,
                department: null,
                subDepartment: null,
                //orgChartLayer: null,
                departmentHeadTitle: null,
            },
            addHeirarchyDialog: false,
            rules: {
                required: value => !!value || 'Required Field',
                validselection: v => (v && v != -1) || 'Required Field',
                departmentExists: v => (v && this)
            },
            treeConfig: { nodeWidth: 300, nodeHeight: 150, levelHeight: 200 },
            levelToAdd: '',
            addedLevelName: '',
            valid: false,
            orgChart: {
                name: "Company:" + this.$store.getters.customer,
                children: [],
                // identifier: 'customID'
            },
            heirarchyActions: [
                "Country",
                "City",
                "Department",
                "Sub-Department"
            ],
            currentSelectedCustomer: null,
            countries: [],
            allCities: [],
            coumtryCities: [],
            orgStructureItems: [],
            orgStructureItemsfields: [
                "customerNumber",
                "country",
                "city",
                "department",
                "subDepartment",
                "grade",
                //"layer",
                "Action"],
            form: {
                id: null,
                customerNumber: 5,
                orgChartCountry: null,
                orgChartCity: null,
                orgChartDepartment: null,
                orgChartSubDepartment: null,
                orgChartGrade: null,
                //orgChartLayer: null
            },
            editForm: {
                id: null,
                customerNumber: 5,
                orgChartCountry: null,
                orgChartCity: null,
                orgChartDepartment: null,
                orgChartSubDepartment: null,
                orgChartGrade: null,
                //orgChartLayer: null
            },
            currentEditedOrgChart: null
        }
    },
    validations: {
        form: {
            orgChartCountry: {
                required
            },
            orgChartCity: {
                required
            },
            orgChartDepartment: {
                required
            },
        },
        editForm: {
            orgChartCountry: {
                required
            },
            orgChartCity: {
                required
            },
            orgChartDepartment: {
                required
            },
        }
    },
    methods: {
        validateNew() {
            if (this.$refs.Addform.validate()) {
                this.addOrgChart();
                this.clear();
            }
        },
        clear() {
            this.editedItem.id = -1;
            this.editedItem.countryId = -1;
            this.editedItem.cityId = -1;
            this.editedItem.department = null;
            this.editedItem.subDepartment = null;
            //this.editedItem.orgChartLayer = null;
        },
        addDialog(item, index) {
            this.clear();
            this.addedLevelName = item;
            this.levelToAdd = index;
            this.addHeirarchyDialog = true;
        },
        editOrgStructure(item, index) {
            this.editForm.id = item.id,
                this.editForm.orgChartCountry =
                this.countries.filter(o => o.countryName == item.country)[0].id
            this.countryChanged(this.form.orgChartCountry);
            this.$nextTick(() => {
                this.editForm.orgChartCity =
                    this.coumtryCities.filter(o => o.cityName == item.city)[0].id;
                this.editForm.orgChartDepartment = item.department;
                this.editForm.orgChartSubDepartment = item.subDepartment;
                this.editForm.orgChartGrade = item.grade;
                //this.editForm.orgChartLayer = item.layer;
            });

            this.$bvModal.show('EditOrgStructure');
            this.currentEditedOrgChart = index;

        },
        updateOrgStructure() {
            this.$v.editForm.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/BusinessSector`,
                data: {
                    Id: this.editForm.id,
                    CustomerNumber: this.editForm.customerNumber,
                    CityId: this.editForm.orgChartCity,
                    Department: this.editForm.orgChartDepartment,
                    SubDepartment: this.editForm.orgChartSubDepartment,
                    Grade: this.editForm.orgChartGrade,
                    //Layer: this.editForm.orgChartLayer,
                }
            }).then((response) => {
                if (response.data == true) {
                    this.items[this.currentEditedSector] = this.editForm;
                    this.onReset();
                    this.$bvModal.hide('EditBusinessSector');
                    this.$refs.BusinessSectorsList.refresh();

                }
            });
        },
        deleteNode: function (node) {
            this.selectedDeleteNode = node
            this.approveDeleteNodeDialig = true;
        },
        addJob: function (node) {
            this.selectedNode = node
            this.addJobDialog = true;
        },
        deleteOrgStructure() {
            const that = this;
            var heirarchy = this.selectedDeleteNode.split('_');
            switch (heirarchy.length) {
                case 1: // delete country level node
                    var countryNodes = this.orgStructureItems.filter(d => d.country == heirarchy[0])
                    countryNodes.forEach((node) => {
                        this.$axios({
                            method: 'delete',
                            url: `${this.$baseURL}/CustomerProfile/OrgChart`,
                            params: {
                                orgChartId: node.id,
                                customerNumber: this.customerNumber
                            }
                        }).then(() => {
                            that.getOrgCharts();
                            that.approveDeleteNodeDialig = false;
                            that.alertMsg = "Organization chart updated successfully"
                            that.snackbar = true;
                        });
                    })
                    break;
                case 2: // delete city level node
                    var cityNodes = this.orgStructureItems.filter(d => d.country == heirarchy[0] && d.city == heirarchy[1])
                    cityNodes.forEach((node) => {
                        this.$axios({
                            method: 'delete',
                            url: `${this.$baseURL}/CustomerProfile/OrgChart`,
                            params: {
                                orgChartId: node.id,
                                CustomerNumber: this.customerNumber
                            }
                        }).then(() => {
                            that.getOrgCharts();
                            that.approveDeleteNodeDialig = false;
                            that.alertMsg = "Organization chart updated successfully"
                            that.snackbar = true;
                        });
                    })
                    break;
                case 3: // delete department level node
                    var departmentNodes = this.orgStructureItems.filter(d => d.country == heirarchy[0] && d.city == heirarchy[1] && d.department == heirarchy[2])
                    departmentNodes.forEach((node) => {
                        this.$axios({
                            method: 'delete',
                            url: `${this.$baseURL}/CustomerProfile/OrgChart`,
                            params: {
                                orgChartId: node.id,
                                CustomerNumber: this.customerNumber
                            }
                        }).then(() => {
                            that.getOrgCharts();
                            that.approveDeleteNodeDialig = false;
                            that.alertMsg = "Organization chart updated successfully"
                            that.snackbar = true;
                        });
                    })

                    break;
                case 4: // delete sub-department level node
                    var subdepartmentNodes = this.orgStructureItems.filter(d => d.country == heirarchy[0] && d.city == heirarchy[1] && d.department == heirarchy[2] && d.subDepartment == heirarchy[3])
                    subdepartmentNodes.forEach((node) => {
                        this.$axios({
                            method: 'delete',
                            url: `${this.$baseURL}/CustomerProfile/OrgChart`,
                            params: {
                                orgChartId: node.id,
                                CustomerNumber: this.customerNumber
                            }
                        }).then(() => {
                            that.getOrgCharts();
                            that.approveDeleteNodeDialig = false;
                            that.alertMsg = "Organization chart updated successfully"
                            that.snackbar = true;

                        });
                    })
                    break;
            }


        },
        cityChanged(item) {
            if (this.levelToAdd == 3) { // this is the only case that we need to have departments list

                var selectedCity = this.allCities[0].filter(c => c.id == item)[0];
                var selectedCountry = this.countries.filter(c => c.id == this.editedItem.countryId)[0];
                let cityDepartments = this.orgStructureItems.filter(d => d.country == selectedCountry.countryName && d.city == selectedCity.cityName && d.department != null);
                var uniqueDepartments = Array.from(new Set(cityDepartments.map((item) => item.department)));

                this.cityDepartments = uniqueDepartments;
            }


        },
        countryChanged(item) {

            var selectedCountry = this.countries.filter(c => c.id == item)[0];

            let countryCities = this.orgStructureItems.filter(d => d.country == selectedCountry.countryName);

            var uniqueCities = Array.from(new Set(countryCities.map((item) => item.city)));


            if (this.levelToAdd == 1) { // add new city then select all cities of this country excep those already added

                if (this.allCities != null && this.allCities.length > 0) {
                    this.coumtryCities = this.$store.getters.getCities.filter(function (city) {
                        return city.countryId == item && uniqueCities.indexOf(city.cityName) == -1;
                    });
                }

                if (this.coumtryCities == null || this.coumtryCities.length == 0) {
                    this.$axios({
                        method: 'get',
                        url: `${this.$baseURL}/CustomerProfile/cities?countryId=${item}`,
                    })
                        .then((response) => {
                            if (this.allCities == null || this.allCities.length == 0) {
                                this.allCities.push(response.data)
                            }
                            else {
                                this.allCities.concat(response.data);
                            }
                            this.coumtryCities = response.data.filter(c => uniqueCities.indexOf(c.cityName) == -1);
                        });
                }

            }

            if (this.levelToAdd > 1) { // add new Department/Sub-Department then add only existing cities

                if (this.allCities != null && this.allCities.length > 0) {
                    this.coumtryCities = this.$store.getters.getCities.filter(function (city) {
                        return city.countryId == item && uniqueCities.indexOf(city.cityName) != -1;
                    });
                }


                if (this.coumtryCities == null || this.coumtryCities.length == 0) {
                    this.$axios({
                        method: 'get',
                        url: `${this.$baseURL}/CustomerProfile/cities?countryId=${item}`,
                    })
                        .then((response) => {
                            if (this.allCities == null || this.allCities.length == 0) {
                                this.allCities.push(response.data)
                            }
                            else {
                                this.allCities.concat(response.data);
                            }
                            this.coumtryCities = response.data.filter(c => uniqueCities.indexOf(c.cityName) != -1);
                        });
                }


            }




        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        addOrgChart() {

            this.$axios({
                method: 'post',
                url: `${this.$baseURL}/CustomerProfile/OrgChart`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    CustomerNumber: this.customerNumber,
                    CountryId: this.editedItem.countryId,
                    CityId: this.editedItem.cityId == -1 ? null : this.editedItem.cityId,
                    Department: this.editedItem.department,
                    SubDepartment: this.editedItem.subDepartment,
                    //Layer: this.editedItem.orgChartLayer,
                    DepartmentHeadTitle: this.editedItem.departmentHeadTitle
                }
            }).then(() => {
                this.addHeirarchyDialog = false;
                this.alertMsg = "Organization chart updated successfully"
                this.snackbar = true;
                this.getOrgCharts();
            });
        },
        getlookups() {
            if (this.countries.length == 0) {
                this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/CustomerProfile/countrires`,
                })
                    .then((response) => {
                        this.countries = response.data;
                    });
            }
        },
        getOrgCharts(selectedCustomer) {
            //  if(this.orgChart.children!=null && this.orgChart.children.length > 0){
            //      this.orgChart.children = [];
            //  }
            this.orgChart.children = [];

            if (selectedCustomer) {
                this.orgChart.name = "Company:" + selectedCustomer.text
                this.currentSelectedCustomer = selectedCustomer.customerNumber;

            }
            else {
                this.currentSelectedCustomer = this.customerNumber;
            }

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/OrgChart?customerNumber=${this.currentSelectedCustomer}`,
            })
                .then((response) => {
                    this.orgStructureItems = response.data;
                    console.log(response.data)
                    // this.$nextTick(() => {



                    //1- get unique countries
                    let countries = [];
                    countries = Array.from(new Set(response.data.map((item) => item.country)));


                    var newGuid = new Guid();
                    countries.forEach((country) => {

                        //2- Add country to the Org chart
                        var countryNode = {
                            name: "Country:" + country,
                            id: country,
                            _key: newGuid.newGuid()
                        }
                        this.orgChart.children.push(countryNode);


                        //3- get country cities
                        let countryCitiesItems = response.data.filter(d => d.country == country && d.city != null);
                        if (countryCitiesItems.length > 0) {

                            //4- Add cities to the Org chart
                            countryNode.children = [];
                            var uniqueCities = Array.from(new Set(countryCitiesItems.map((item) => item.city)));

                            uniqueCities.forEach((city) => {
                                var cityNode = {
                                    name: "City:" + city,
                                    id: country + "_" + city,
                                    _key: newGuid.newGuid(),
                                }
                                countryNode.children.push(cityNode);


                                //5- get department for each city
                                let departmentItems = response.data.filter(d => d.country == country && d.city == city && d.department != null);
                                if (departmentItems.length > 0) {
                                    //6- Add departments to city
                                    cityNode.children = [];
                                    var uniqueDepartments = Array.from(new Set(departmentItems.map((item) => item.department)));


                                    uniqueDepartments.forEach((department) => {
                                        var departmentNode = {
                                            name: "Department:" + department,
                                            id: country + "_" + city + "_" + department,
                                            _key: newGuid.newGuid(),
                                        }
                                        cityNode.children.push(departmentNode);

                                        //7 get subdepartments for each department
                                        let subDepartmentItems = response.data.filter(d => d.country == country && d.city == city && d.department == department && d.subDepartment != null);
                                        if (subDepartmentItems.length > 0) {
                                            departmentNode.children = [];
                                            var uniqueSubDepartments = Array.from(new Set(subDepartmentItems.map((item) => item.subDepartment)));

                                            uniqueSubDepartments.forEach((subDepartment) => {
                                                var SubdepartmentNode = {
                                                    name: "Sub-Department:" + subDepartment,
                                                    id: country + "_" + city + "_" + department + "_" + subDepartment,
                                                    _key: newGuid.newGuid(),
                                                }
                                                departmentNode.children.push(SubdepartmentNode);

                                            });

                                        }
                                    });


                                }

                            });


                        }

                    });





                });

            this.$refs.tree.init();
        },
        onReset() {
            this.form.id = null;
            this.form.orgChartCountry = null;
            this.form.orgChartCity = null;
            this.form.orgChartDepartment = null;
            this.form.orgChartSubDepartment = null;
            this.form.orgChartGrade = null;
            //this.form.orgChartLayer = null;

            this.editForm.id = null;
            this.editForm.orgChartCountry = null;
            this.editForm.orgChartCity = null;
            this.editForm.orgChartDepartment = null;
            this.editForm.orgChartSubDepartment = null;
            this.editForm.orgChartGrade = null;
            //this.editForm.orgChartLayer = null;
        }
    },
    mounted() {
        this.getlookups();
        if (this.customerNumber > 0) {
            this.getOrgCharts();
        }
    },
    created() {
        // this.orgChart.children.push({
        //                     name:"asdasd",
        //                     customID:2
        //                 });

        //                  this.orgChart.children.push({
        //                     name:"asdasd",
        //                     customID:2
        //                 });
    }
}
</script>

<style scoped>
.containersss {
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>